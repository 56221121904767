import AuthProvider from './auth_service';
import MemberProvider from './member_service';
import PublicProvider from './public_service';
import UploadfileProvider from './uploadfile_service';

// ratta add 
import OrganizeProvider from './organize_service';
import CommonProvider from './common_service';
import ReasonProvider from './reason_service';
import MedicineProvider from './medicine_service';
import AppointmentProvider from './appointment_service';
import PatientcaseProvider from './patientcase_service';
import MedboxProvider from './medbox_service';

// Pruke add
import MapProvider from './map_service'

// import SurveyProvider from './survey_service';
import AdminProvider from './admin_service';
// import MasterProvider from './master_service';

export const authService = new AuthProvider('auth');
export const memberService = new MemberProvider('member');
export const publicService = new PublicProvider('public');
export const uploadFileService = new UploadfileProvider('uploadfile');

// export const surveyService = new SurveyProvider('survey');
export const adminService = new AdminProvider('admin');
// export const masterService = new MasterProvider('master');

// ratta add 
export const organizeService = new OrganizeProvider('organize');
export const commonService = new CommonProvider('common');
export const reasonService = new ReasonProvider('reason');
export const medicineService = new MedicineProvider('medicine');
export const appointmentService = new AppointmentProvider('appointment');
export const patientcaseService = new PatientcaseProvider('patientcase'); 
export const medboxService = new MedboxProvider('medbox'); 

// Pruke add

export const mapService = new MapProvider('map');
