import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
const app = createApp(App);

// bootstrap
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

// modals
import '@/assets/sass/components/custom-modal.scss';

// perfect scrollbar
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';

//vue-meta
import { createHead } from '@vueuse/head';
const head = createHead();

//Sweetalert
import Swal from 'sweetalert2';
window.Swal = Swal;

// nouislider - later remove and add to page due to not working in page
import VueNouislider from 'vue3-nouislider';
import 'vue3-nouislider/dist/vue3-nouislider.css';

// vue input mask
import Maska from 'maska';

// smooth scroll
import { registerScrollSpy } from 'vue3-scroll-spy/dist/index';
registerScrollSpy(app, { offset: 118 });

//vue-i18n
import i18n from './i18n';

// datatables
import { ClientTable } from 'v-tables-3';

// json to excel
import vue3JsonExcel from 'vue3-json-excel';

//vue-wizard
import VueFormWizard from 'vue3-form-wizard';
import 'vue3-form-wizard/dist/style.css';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

/* add icons to the library */
library.add(fas);
library.add(fab);
library.add(far);

// set default settings
import appSetting from './app-setting';
window.$appSetting = appSetting;
window.$appSetting.init();

import { initFacebookSdk } from './plugins/initFacebookSdk';
initFacebookSdk();

import vue3GoogleLogin from 'vue3-google-login';
import VueApexCharts from "vue3-apexcharts";

/* import googlemaps js-api-loader */
import { Loader } from '@googlemaps/js-api-loader';
const googleMapsLoader = new Loader({
  apiKey: 'AIzaSyDDVNwpO8gYMQwMeoKfpnqM_c3NJyDleu0',
  version: 'beta',
  libraries: ['marker', 'places','visualization']
});
app.config.globalProperties.$googleMapsLoader = googleMapsLoader;

// import component globally

/** set global variable value */
const globalVar = {
  // lastTopicID: 16873, // last topic before csite 2024
  // lastTopicID: 39956, // last topic before csite 2024
  lastTopicID: 100000, // last topic before csite 2024
  csiteWebUrl: 'https://www.csitereport.com',
  // csiteWebUrl: 'https://dev.csitereport.com',
  s3DevUrl: 'https://s3-ap-southeast-1.amazonaws.com/com.csitereport.s3.dev',
  s3RedesignUrl: 'https://sgp1.digitaloceanspaces.com/csite-redesign',
  shareServiceUrl: 'https://share.csitereport.com/',
  urlDev: 'dev.csitereport.com',
};
app.config.globalProperties.$globalVar = globalVar;

app.use(store)
  .use(router)
  .use(i18n)
  .use(PerfectScrollbar)
  .use(VueNouislider)
  .use(Maska)
  .use(ClientTable)
  .use(vue3JsonExcel)
  .use(VueFormWizard)
  .use(vue3GoogleLogin, {
    clientId: '875409830521-t9h70sp6outbq617v8dkkt5n3aj4d4c8.apps.googleusercontent.com'
  })
  .use(VueApexCharts)
  .component('font-awesome-icon', FontAwesomeIcon)

  .use(head).mount('#app');
