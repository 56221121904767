import HttpRequest from './core/http_request';

class MapProvider extends HttpRequest {
// telemed Pruke add 06/01/2025 =======================================================================
  getLocation (data) {
     return this.request('get', '/webapp/map/getLocation', data);
  }
  getDisease (data) {
    return this.request('post', '/webapp/map/getDisease', data);
 }
 getReason (data) {
  return this.request('get', '/webapp/map/getReason', data);
}
//   getMemberList (data) {
//     return this.request('get', '/webapp/member', data);
//   }


//   updateMember (data) {
//     const id = data._id;
//     delete data._id;
//     return this.request('post', '/webapp/member/id/' + id, data);
//   }

}

export default MapProvider;
