import HttpRequest from './core/http_request';

class MemberProvider extends HttpRequest {
  // telemed ratta add 20/06/2024 =======================================================================
  getMemberList (data) {
    return this.request('get', '/webapp/member', data);
  }

  getMemberByID (data) {
    return this.request('get', '/webapp/member/id/' + data._id);
  }

  updateMember (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/webapp/member/id/' + id, data);
  }

  deleteMember (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/webapp/member/delete/' + id);
  }

  getPatienttypeList () {
    return this.request('get', '/webapp/member/patienttype');
  }

  getpatientBymemberID (id) {
    return this.request('get', '/webapp/member/patientbymemberid/' + id);
  }

  checkMemberByID (id) {
    return this.request('get', '/webapp/member/checkmemberbyid/' + id);
  }

  updateMemberStatusById (data) {
    return this.request('post', '/webapp/member/updateMemberStatusById/' + data.member_id, data);
  }
}

export default MemberProvider;
